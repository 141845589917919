<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import "../teclados/keyCustom.css";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    input: {
      type: String
    },
    layout: {
      type: Object
    },
    buttonTheme: {
      type: Array
    }
  },
  data: () => ({
    keyboard: null,
    clicked: false,
  }),
  mounted() {
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: this.layout,  
      display: {
        '{bksp}': "⌫",
      },    
      theme: "hg-theme-default hg-layout-numeric numeric-theme",
      buttonTheme: this.buttonTheme,                
    });
  },

  methods: {
    onChange(input) {
      if(!this.clicked)
        {
          this.clicked = true;
          this.$emit("onChange", input);
          setTimeout(() => {
            this.clicked = false;
          }, 200);
        } else {
          this.keyboard.setInput(input.slice(0,-1))
        }           
    },
    onKeyPress(button) {
      if (button === '{bksp}') {
        this.clicked = false
      }
      this.$emit("onKeyPress", button);      

      /**
       * If you want to handle the shift and caps lock buttons
       */
         if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,    
      });
    }
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input)     
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>